import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import qs from 'qs';

import Header from './Header';
import Footer from './Footer';

import Popups, {ShowError} from './Modules/Popups';

import empty_image from "../images/empty.png";
import image_bg from '../images/help/header-bg.jpg';
import image_logo from '../images/help/logo.jpg';


const Feed = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['name']);
    const { t, i18n } = useTranslation(); const changeLanguage = (language) => { i18n.changeLanguage(language); };

    const [InvalidToken, SetInvalidToken] = React.useState(false);

    const [Id, SetId] = React.useState(0);
    const [Username, SetUsername] = React.useState("Username");
    const [Balance, SetBalance] = React.useState(0);

    const [UsersCount, SetUsersCount] = React.useState(0);
    const [News, SetNews] = React.useState([]);

    function decrypt(encryptedData) {
        const encryptedBytes = atob(encryptedData);
        const keyBytes = unescape(encodeURIComponent("MyEncryptionKey123"));

        const decryptedBytes = new Uint8Array(encryptedBytes.length);

        for (let i = 0; i < encryptedBytes.length; i++) {
            const encryptedByte = encryptedBytes.charCodeAt(i);
            const keyByte = keyBytes.charCodeAt(i % keyBytes.length);

            // Пропускаем символы, не соответствующие ASCII
            if (encryptedByte > 255 || keyByte > 255) {
                decryptedBytes[i] = encryptedByte;
            } else {
                decryptedBytes[i] = encryptedByte ^ keyByte;
            }
        }

        const decoder = new TextDecoder('utf-8');
        const decryptedString = decoder.decode(decryptedBytes);

        return JSON.parse(decryptedString);
    }

    function GetHwid() {
        var browser = navigator.userAgent;
        var gl = document.createElement("canvas").getContext("webgl");
        var ext = gl.getExtension("WEBGL_debug_renderer_info");
        var videocard;
        if(ext) videocard = gl.getParameter(ext.UNMASKED_RENDERER_WEBGL);

        return browser+videocard;
    }
    function GetLang() {
        const language = navigator.language || navigator.userLanguage;
        //console.log(language); // Выведет язык в формате "en-US", "ru-RU", "zh-CN" и т.д.
        return language.split('-')[0];
    }
    function PageLanguage() {

    }

    useEffect(()=> {
        document.getElementById("_feed").className = "active";

        axios.post('https://api.0xcheats.su/Account/Info', qs.stringify({ 'token': cookies.token, 'ahwid': GetHwid() }))
        .then(function(response) {
            const data = JSON.stringify(decrypt(response.data));
            const json = JSON.parse(data);
            
            if (json.success == "true") {

                //Аккаунт
                SetId(json.id);
                SetUsername(json.username);
                SetBalance(json.balance);
            }
            else if (json.failed != null) {
                SetInvalidToken(true);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {

        });

        //Язык новостей
        if (cookies.feed_lang == null)
        {
            setCookie("feed_lang", GetLang(), { path: "/" });
            window.location.reload();
        }
        else
        {
            LoadPosts(cookies.feed_lang, true);
        }
    }, [])


    function LoadPosts(lang, init) {
        document.getElementById("button-lang-ru").classList.remove("active");
        document.getElementById("button-lang-en").classList.remove("active");
        document.getElementById("button-lang-cn").classList.remove("active");
        
        if (init == true)
            document.getElementById("button-lang-" + cookies.feed_lang).classList.add("active");
        else
            document.getElementById("button-lang-" + lang).classList.add("active");

        axios.post('https://api.0xcheats.su/Feed', qs.stringify({ 'token': cookies.token, 'lang': lang, 'ahwid': GetHwid() }))
        .then(function(response) {
            const data = JSON.stringify(decrypt(response.data));
            const json = JSON.parse(data);
            
            if (json.success == "true") {
                SetUsersCount(json.users);
                SetNews(json.posts);
            }
            else if (json.failed != null) {
                SetInvalidToken(true);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {

        });
    }
    function ChangePostLanguage(lang) {
        setCookie("feed_lang", lang, { path: "/" });
        LoadPosts(lang, false);
    }


    function DateFormat(date) {
        const newDate = new Date(date);
        const day = newDate.getDate().toString().padStart(2, '0');
        const month = (newDate.getMonth() + 1).toString().padStart(2, '0'); // Месяцы начинаются с 0
        const year = newDate.getFullYear();
        const hours = newDate.getHours().toString().padStart(2, '0');
        const minutes = newDate.getMinutes().toString().padStart(2, '0');
        const formattedDate = `${day}.${month}.${year} в ${hours}:${minutes}`;
        return formattedDate;
    }
    function NumberFormat(value) {
        if (value > 0)
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        else
            return "";
    }

    function NewsTitle(props) {
        if (props.title.length > 0) {
            return <><b>{props.title}</b><br/><br/></>;
        }
        return "";
    }
    function NewsImage(props) {
        if (props.image.length > 3) {
            return <><br/><br/><img style={{borderRadius:'10px'}} src={props.image} alt="" draggable="false"/></>;
        }
        return "";
    }
    function NewsList() {
        if (News.length > 0) {
            return News.map((post) =>
                <div key={post.id} className="post">
                    <div className="header-p">
                        <img src={image_logo} alt=""/>
                        <div>
                            <span className="name">0XCHEATS.NET</span>
                            <span className="date">{DateFormat(post.date)}</span>
                        </div>
                    </div>
                    <div className="text-p">
                        <NewsTitle title={post.title}/>
                        {post.body}
                        <NewsImage image={post.image}/>
                    </div>
                    <div className="actions">
                        <p className="send-like"><i className="icon like"></i>{post.likes}</p>
                        <div className="views-count"><i className="icon eye"></i>{post.views}</div>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className="post">
                    <div className="header-p">
                        <img src={image_logo} alt=""/>
                        <div>
                            <span className="name">0XCHEATS.NET</span>
                            <span className="date loading" style={{color:'#FFFFFF00'}}>Когда-то в 6:66</span>
                        </div>
                    </div>
                    <div className="text-p">
                        <a className="loading">Обновление | RDR Online<br/></a>
                        <br/>
                        <a className="loading">- Вкладка Мир - Добавлена функция Размер времени;<br/></a>
                        <a className="loading">- Lorem ipsum dolor sit amet, consectetur adipisicing elit.<br/></a>
                        <a className="loading">- Id omnis aut velit, est enim ab, tenetur maxime officia!<br/></a>
                        <a className="loading">- Delectus aperiam sed.<br/></a>
                        <br/>
                        <img className="loading" src={empty_image} alt=""/>
                    </div>
                    <div className="actions">
                        <p></p>
                        <div className="views-count loading" style={{color:'#FFFFFF00'}}>100500K</div>
                    </div>
                </div>
            );
        //}
    }
    }

    return (
    <>
        <Popups/>

        <Header/>

        <section id="news" className="fadeout">
            <div className="container">
                <div className="content">
                    <div className="header">
                        <img src={image_bg} alt="" className="bg"/>
                        <div className="info">
                            <div className="profile">
                                <img src={image_logo} alt=""/>
                                <div>
                                    <span className="name">0XCHEATS.NET</span>
                                    <span>USE 0x, BE AHEAD</span>
                                </div>
                            </div>
                            <Link to="/account" className={InvalidToken ? "hide" : "btn"}><span>{t('feed_subscription1')}</span></Link>
                            <Link to="/auth" className={InvalidToken ? "btn" : "hide"}><span>{t('feed_subscription2')}</span></Link>
                        </div>
                    </div>
                    <div className="main">
                        <div className="posts">
                            <div className="search">
                                <p>{t('feed_title')}</p>
                                <div className="input">
                                    <p id="button-lang-ru" className="feed_language active" onClick={() => ChangePostLanguage("ru")}>RU</p>
                                    <p id="button-lang-en" className="feed_language" onClick={() => ChangePostLanguage("en")}>EN</p>
                                    <p id="button-lang-cn" className="feed_language" onClick={() => ChangePostLanguage("cn")}>CN</p>
                                </div>
                                {/*<div className="input"><input type="text" placeholder="Поиск..."/><i className="icon search-i"></i></div>*/}
                            </div>

                            <NewsList/>

                        </div>
                        <div className="followers">
                            <p className="pre-title fadeout"><span>{t('feed_users')}</span>{NumberFormat(UsersCount)}</p>
                            <div className="list">
                                {/*<div className="follower-wrapper">
                                    <div className="follower">
                                        <img src="../img/avatar.jpg" alt=""/>
                                        Хината Хьюга
                                    </div>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Footer/>
    </>
    );
};
export default Feed;